<template>
  <v-card
    elevation="2"
    class="card_shipment text-center pb-1"
    :class="isStatus ? 'status-card' : ''"
    style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09) !important"
  >
  <div class="slot-btns">
      <slot />
    </div>
    <!-- class="product-card text-center relative h-100 flex-between-column px-3" -->
    <div class="owen-item px-1" v-if="userID == item.owner_id">
      <v-icon color="primary" size="12">mdi-star</v-icon>
    </div>
    <router-link :to="route">
      <div
        v-if="closeIcon"
        class="close-icon text-right"
        @click="$emit('closeClicked')"
      >
        <v-icon color="secondary">mdi-close</v-icon>
      </div>

      <div class="mt-2 d-flex align-center justify-center" v-if="item.image || item.bringer_image">
        <v-img
          :src="item.image || item.bringer_image"
          height="160px"
          style="object-fit: fill"
          width="90%"
          class="rounded-lg mx-3 my-3 object-contain"
        />
      </div>

      <div
        z-index="10"
        class="px-2 py-1 bg_primary halfwhite--text font-15 weight_kg"
        :class=" $route.name=='shipments' ? 'topProsses' : 'topProsses2'"
      >
        {{ $t("weight") }}: {{ item.weight }} {{$t('kg')}}
      </div>

      <!-- title -->
      <div v-if="item.title" class="title_card">
        <span class="text-center primary--text font-30"
          >{{
            item.title.length > 20
              ? "..." + item.title.slice(0, 20)
              : item.title
          }}
        </span>
      </div>
      <div class="mx-1 d-flex justify-space-between">
        <div class="d-flex">
          <v-img
            width="20"
            height="20"
            class="mx-1"
            :src="require('@/assets/images/profile/calendar.png')"
          />
          <span class="font-22 ml-1 lightGreyText--text" v-if="item.date">
            {{ item.date }}</span
          >
        </div>
        <div class="d-flex" v-if="item.budget">
          <span class="font-22 ml-1 lightGreyText--text"
            >{{ $t("budget") }}: {{ item.budget }}$</span
          >
        </div>
        <div v-if="item.price">
          <span  class="font-20 d-block black--text"
          >{{ $t("price") }}: {{ item.price }}$
        </span>
        </div>
      </div>

      <div
        class="d-flex justify-space-between bg_primary py-2 px-2 my-1"
        v-if="item.fromCountry"
      >
        <div>
          <span class="halfwhite--text ">{{ item.fromCountry }}</span>
        </div>
        <div>
          <v-img
            width="20"
            :src="require('@/assets/images/profile/airplane.png')"
          />
        </div>
        <div>
          <span class="halfwhite--text">{{ item.toCountry }}</span>
        </div>
      </div>

      <div class="mt-3" v-if="item.fromCountry">
        <!-- <span class="font-20 font-500 d-block black--text"
          >{{ $t("trip") }} {{ $t("from") }} {{ item.fromCountry }}
          {{ $t("to") }} {{ item.toCountry }}
        </span> -->
        <span
          v-if="item.companyTitle"
          class="font-15 font-300 d-block black--text"
          >ANAPOG Stuff
        </span>
      </div>

      <div class="d-flex align-item-center justify-space-between mx-2 mt-2" v-if="item.bringer_name">
        <span class="font-15 font-300 d-block black--text"
          >{{ item.bringer_name }}
        </span>
        <v-rating
        class=""
        v-if="item.rate || item.rate == '0'"
        :value="item.rate"
        background-color="darkgray"
        empty-icon="mdi-star"
        color="yellow"
        size="13"
        readonly
      ></v-rating>
      </div>
      <!-- rate -->
 
      <!-- from-to -->
      <div class="d-flex mb-2 mt-1 mx-2 ">
        <div class="from_to_card" v-if="item.fromCity">
          <div></div>
          <div class="line"></div>
          <div></div>
        </div>

        <div
          class="content-location flex-column"
          v-if="item.fromCity || item.toCity"
          align="start"
          style="background-color: $primary"
        >
          <div class="d-flex align-center mb-2" v-if="item.fromCity">
            <span
              class="font-15 d-block mx-1 black--text d-block mx-1"
              style="width: 27px"
              >{{ $t("from") }}</span
            >
            <div class="outline_button px-2">
              <span class="black--text font-20 location-title">{{
                item.fromCity
              }}</span>
            </div>
          </div>
          <div class="d-flex align-center">
            <span
              style="width: 27px"
              class="font-15 d-block mx-1 black--text d-block mx-1 w-25"
              v-if="item.toCity"
              >{{ $t("to") }}</span
            >
            <div class="outline_button px-2">
              <span class="black--text font-20 location-title">{{
                item.toCity
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- details -->

    </router-link>
    <span
      class="font-15 d-block mx-1 black--text text-start d-block mx-2"
      v-if="item.categories"
      >{{ $t("categories") }}
    </span>
    <v-row justify="space-around" v-if="item.categories">
      <v-col cols="12" v-if="item.categories.name">
        <v-sheet elevation="10" class="py-1 px-2">
          <v-chip-group multiple active-class="primary--text">
            <v-chip style="background-color: #f0f5f8">
              {{ item.categories.name }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-else>
        <v-sheet elevation="10" class="py-1 px-2">
          <v-chip-group multiple active-class="primary--text">
            <v-chip
              v-for="(categories, i) in item.categories"
              :key="i"
              style="background-color: #f0f5f8"
            >
              {{ categories.name }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- buttons -->
    <div v-if="isStatus" style="height: 30px"></div>

    <!-- status -->
    <slot name="status" />

    <!-- delete icon -->
    <slot name="delete" />

    <!-- bids count -->
    <slot name="bids" />
    <slot name="slotBtnRequest"></slot>

  </v-card>

</template>

<script>
export default {
  props: {
    route: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: require("@/assets/images/global/user.png"),
    },
    company: {
      type: Boolean,
      default: false,
    },
    rate: {
      type: Boolean,
      default: true,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rating: 3,
    product: { weight: "10", date: "10" },
    settings: {
      arrows: true,
      touchMove: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  methods: {
    stopRoute() {
      console.log("bla");
    },
  },
};
</script>

<style lang="scss">
.title_card {
  // margin-top: -25px !important;
}
.v-application .elevation-10 {
  box-shadow: none !important;
}
.from_to_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    background-color: #00148969;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .line {
    height: 36px;
    border-left: 1px solid #00148969;
    width: 0px;
  }
}
.product-card {
  position: relative;
  padding: 15px;
  border-radius: 15px;
  background: white;
  border: 1px solid #c2c2c2;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .location-title {
    display: block;
    max-width: 200px;
    white-space: pre-wrap;
    padding: 5px;
  }
}
.slot-btns {
  position: relative;
  z-index: 99;
}
.status-card {
  .slot-btns {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 3;
  }
}
.owen-item {
  position: absolute;
  top: 0px;
  left: 0px;
  // background: $primary;
}
.product-slider {
  height: 140px;
  width: 90px;
  // background: #eaeaea;
  border-radius: 7px;
  padding: 10px 7px;
  overflow: hidden;
  .slider-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    margin: auto !important;
    width: 100%;
    height: 100%;
  }
}
.content-location {
  justify-content: start;
}
@media (max-width: 600px) {
  .user-img {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  .content-side {
    text-align: left;
  }
  .content-location {
    justify-content: start;
    .c-btn {
      width: 130px;
    }
    .v-btn__content {
      span {
        font-size: 9px;
      }
    }
  }
}
.bg_primary {
  background-color: #001489;
}
.theme--light.v-chip:not(.v-chip--active) {
  margin-bottom: 5px;
}
.card_shipment {
  .weight_kg {
    width: 120px;
    text-align: center;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom: 0px;
    position: absolute;
    margin: auto;
    left: 33%;
  }
  .topProsses {
    position: absolute;
    top: 160px;
  }
  .topProsses2 {
    position: absolute;
    top: 146px;
  }
  .outline_button {
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #00148969;
    border-radius: 12px;
    padding: 1px;
  }
}
</style>
