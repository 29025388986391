<template>
  <div class="profile-trips max-width">
    <!-- filters -->
    <div class="d-flex align-center justify-space-between mb-5">
      <div class="d-flex align-center">
        <!-- <base-select
          v-model="tripFilter"
          :placeholder="$t('all')"
          :items="tripFilters"
          :rules="[]"
          @input="tripFilterHandler"
        ></base-select> -->
      </div>
      <!-- add new -->
      <v-btn
        class="c-btn px-5"
        color="primary"
        elevation="1"
        height="37"
        @click="$router.push('/add-trip')"
        ><span class="font-25 mx-1">+</span
        ><span class="white--text font-20 font-300">{{
          $t("addNewTrip")
        }}</span></v-btn
      >
    </div>
    <!-- items -->
    <v-row v-if="!loading && items.length > 0">
      <v-col cols="12" sm="6" md="4" v-for="(item, i) in items" :key="i">
        <product-card
          :item="item"
          class="pb-2"
          :slotBtn="true"
          :img="require('@/assets/images/home/laptop.png')"
          :route="'/trip/details/' + item.id"
        >
          <template name="slotBtn">
            <div
              class="d-flex align-center justify-space-between"
              style="
                z-index: 1 !important;
                position: absolute;
                left: 20px;
                right: 20px;
                top: 12px;
              "
            >
              <div class="cursor-pointer">
                <div
                  class="
                    user-number
                    relative
                    graybg-bg
                    pa-1
                    pb-0
                    radius-5
                    cursor-default
                  "
                >
                  <v-icon
                    size="22"
                    color="red"
                    @click.prevent="deleteHandler(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </div>
              <!--  count -->
              <v-badge
                v-if="item.boxCount"
                color="primary"
                :content="item.boxCount"
              >
                <div
                  class="
                    user-number
                    relative
                    graybg-bg
                    pa-1
                    pb-0
                    radius-5
                    cursor-default
                  "
                  style="height: 28px; margin-top: 3px"
                >
                  <img
                    width="18"
                    height="23"
                    :src="require('@/assets/images/profile/ship_activ.png')"
                  />
                </div>
              </v-badge>
              <div
                v-else
                class="
                  user-number
                  relative
                  graybg-bg
                  pa-1
                  pb-0
                  radius-5
                  cursor-default
                "
                style="height: 28px; margin-top: 3px"
              >
                <img
                  width="19"
                  height="20"
                  :src="require('@/assets/images/profile/ship_activ.png')"
                />
              </div>
            </div>
          </template>
        </product-card>
      </v-col>
    </v-row>
    <div class="max-width">
      <no-items
        v-if="!loading && items.length == 0"
        :title="$t('noTrips')"
      ></no-items>
    </div>
    <!-- if empty -->

    <!-- if loading -->
    <div v-if="loading">
      <v-row>
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <skeleton-card />
        </v-col>
      </v-row>
    </div>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-dialog
        @close="deleteDialog = false"
        @ok="okHandler"
        url="/deleteTripByBringer"
        :id="selectedDeleteID"
      ></delete-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "../../../components/core/ProductCard.vue";
import DeleteDialog from "../../../components/dialogs/DeleteShipment.vue";
export default {
  components: { ProductCard, DeleteDialog },
  data: () => ({
    tripsDetails: false,
    deleteDialog: false,
    items: [],
    itemsCopy: [],
    tripFilter: 0,
    tripFilters: [
      { name: "All", id: 0 },
      { name: "Upcoming", id: 1 },
      { name: "Old", id: 2 },
    ],
    selectedDeleteID: "",
    loading: false,
  }),
  methods: {
    deleteHandler(id) {
      this.selectedDeleteID = { key: "trip_id", id: id };
      this.deleteDialog = true;
    },
    okHandler() {
      this.deleteDialog = false;
      this.getData();
    },
    tripFilterHandler(event) {
      if (event == 2) {
        this.items = this.itemsCopy.filter((item) =>
          this.$moment(item.expected_date).isBefore()
        );
      }
      if (event == 1) {
        this.items = this.itemsCopy.filter((item) =>
          this.$moment(item.expected_date).isAfter()
        );
      }
      if (event == 0) {
        this.items = this.itemsCopy;
      }
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get("/getMyTripsDetails");
      // copy the items for filter
      this.items = [
        ...data.data.trips.map((item) => {
          return {
            id: item.id,
            image: item.bringer_image,
            images: [],
            fromCountry: item.origin_country,
            fromCity: item.origin_city,
            toCountry: item.destination_country,
            toCity: item.destination_city,
            date: item.expected_date,
            weight: item.weight,
            rate: +(+item.ratings.split(".")[0]).toFixed(0) || 0,
            categories: item.categories,
            boxCount: item.shipments.length + item.products.length,
          };
        }),
      ];
      this.itemsCopy = [...this.items];
      this.loading = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.profile-trips {
  .base-select {
    fieldset {
      // background: white;
      // border: 1px solid $graybg !important;
      // border-radius: 3px;
    }
    i,
    input {
      // color: $purple;
    }
  }
}
</style>
