<template>
  <div class="delete-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-20 black--text font-500">
        {{$t('dialogs.areYouSure')}}</span
      >
      <img
      width="50"
        class="my-14"
        :src="require('@/assets/images/profile/delete.png')"
        alt=""
      />
      <div class="mb-10">
        <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$emit('close')"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >{{$t('no')}}</span
          ></v-btn
        >
        <v-btn
          class="c-btn mx-1"
          color="red"
          height="40"
          @click="deleteHandler"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >{{$t('yes')}}</span
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    order_id:{
      type:Number,
    }
  },
  methods: {
    async deleteHandler() {
      let {data} = await this.$axios.post(`${this.url}`,{
        [this.id.key]: this.id.id,
        order_id:this.order_id
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Deleted Successfully",
          color: "success",
        });
        this.deleteDialog = false;
        this.$emit('ok')
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
